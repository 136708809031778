import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

import { PageProps } from 'types/pages';

const Error404View = dynamic(
  () => import('@collab/organisms/ErrorView/Error404View'),
);

const InCarApps = dynamic(() => import('pages/InCarApps'));
const Resources = dynamic(() => import('pages/Resources'));
const Cirrus = dynamic(() => import('pages/Cirrus'));
const Home = dynamic(() => import('pages/Home'));
const Page3d = dynamic(() => import('pages/3d'));
const APIs = dynamic(() => import('pages/Apis'));
const Eva = dynamic(() => import('pages/Eva'));
const Reports = dynamic(() => import('pages/Eva/Reports'));
const GettingStarted = dynamic(() => import('pages/GettingStarted'));
const OpenSource = dynamic(() => import('pages/OpenSource'));
const TestAccessTokens = dynamic(() => import('pages/TestAccessTokens'));

type PageMap = Record<string, ComponentType<PageProps>>;

const staticPagesMap: PageMap = {
  index: Home,
  'in-car-apps': InCarApps,
  resources: Resources,
  'resources/cirrus': Cirrus,
  'resources/eva': Eva,
  'resources/eva/reports': Reports,
  '3d': Page3d,
  apis: APIs,
  'apis/docs/getting-started': GettingStarted,
  'apis/docs/test-access-tokens': TestAccessTokens,
  'open-source': OpenSource,
};

const ContentPage: React.FC<
  PageProps & {
    fullPath: string;
    pathPrefix: string | undefined;
  }
> = ({ fullPath, content, pageMeta, pathPrefix = '' }) => {
  const Component = staticPagesMap[pathPrefix + fullPath];

  if (!Component) {
    return <Error404View />;
  }

  return <Component content={content} pageMeta={pageMeta} />;
};

export default ContentPage;
